import react from 'react'
import { useTranslation } from 'react-i18next';

import './styles/Teak.css';



const Teak = () =>{

    const { t, i18n } = useTranslation();


    return(
        <>
        <div className="container-fluid nav-bg" >
        <div className="p-1"></div>

                <div className="row">
                    <div className="col-12 mx-auto container">
                        <div className="col-12 mx-auto teak-div-line border rounded">
                            <div class="w-auto h-25 teak-div-line font-weight-bold text-white "> 
                            {t('menu.teak')}
                            </div>
                        </div>

                        <div className="p-1"></div>
                        <div class="border rounded bordercolor_Teak" >
                            <div class="text-white font-weight-bold d-flex  bgcolor_Teak text-left pl-2 " style={{height: '2rem'}}>
                            {t('teak.description')}
                            </div>

                            <div class="row pl-2 ">
                                <div className="col-6 justify-content-center ">{t('teak.descriptionContent')}</div>
                                <div className="col-6 justify-content-center ">
                                    <img src='../images/Teak/T10.jpg'  class="img-fluid rounded-circle" ></img></div>
                            </div>
                        </div>


                    <div className="p-1"></div>    

                    <div class="border rounded bordercolor_Teak bgcolor_Teak" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample" >
                    <p >
                        <a class="btn-link text-white font-weight-bold pl-2" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                        {t('teak.landselection')}
                        </a>
                    </p>
                        <div class="collapse " id="collapseExample">
                            <div class="col-12 mx-auto ">
                                {t('teak.landselectionContent')}
                            </div>
                        </div>
                    </div>
                    <div className="p-1"></div>

                    <div class="border rounded bordercolor_Teak bgcolor_Teak" data-toggle="collapse" href="#collapseSoil" role="button" aria-expanded="false" aria-controls="collapseExample" >
                    <p >
                        <a class="btn-link text-white font-weight-bold pl-2 " data-toggle="collapse" href="#collapseSoil" role="button" aria-expanded="false" aria-controls="collapseExample">
                        {t('teak.soil')}
                        </a>
                    </p>
                        <div class="collapse " id="collapseSoil">
                            <div class="col-12 mx-auto ">
                                {t('teak.soilContent')}
                            </div>
                        </div>
                    </div>


                    <div className="p-1"></div>


                    <div class="border rounded bordercolor_Teak bgcolor_Teak" data-toggle="collapse" href="#collapseClimate" role="button" aria-expanded="false" aria-controls="collapseExample" >
                    <p >
                        <a class="btn-link text-white font-weight-bold pl-2 " data-toggle="collapse" href="#collapseClimate" role="button" aria-expanded="false" aria-controls="collapseExample">
                        {t('teak.climate')}
                        </a>
                    </p>
                        <div class="collapse " id="collapseClimate">
                            <div class="col-12 mx-auto ">
                                {t('teak.climateContent')}
                            </div>
                        </div>
                    </div>


                    <div className="p-1"></div>

                    <div class="border rounded bordercolor_Teak bgcolor_Teak" data-toggle="collapse" href="#collapseSunlight" role="button" aria-expanded="false" aria-controls="collapseExample" >
                    <p >
                        <a class="btn-link text-white font-weight-bold pl-2 " data-toggle="collapse" href="#collapseSunlight" role="button" aria-expanded="false" aria-controls="collapseExample">
                        {t('teak.sunlight')}
                        </a>
                    </p>
                        <div class="collapse " id="collapseSunlight">
                            <div class="col-12 mx-auto ">
                                {t('teak.sunlightContent')}
                            </div>
                        </div>
                    </div>


                   

                    <div className="p-1"></div>
                    <div class="border rounded bordercolor_Teak bgcolor_Teak"data-toggle="collapse" href="#collapsepreplantationoperations" role="button" aria-expanded="false" aria-controls="collapseExample" >
                    <p >
                        <a class="btn-link text-white font-weight-bold pl-2 " data-toggle="collapse" href="#collapsepreplantationoperations" role="button" aria-expanded="false" aria-controls="collapseExample">
                        {t('teak.preplantationoperations')}
                        </a>
                    </p>
                        <div class="collapse " id="collapsepreplantationoperations">
                            <div class="col-12 mx-auto ">
                                {t('teak.preplantationoperationsContent')}
                            </div>
                        </div>
                    </div>
                    
                    <div className="p-1"></div>

                    <div class="border rounded bordercolor_Teak bgcolor_Teak" data-toggle="collapse" href="#collapsedrainageirrigation" role="button" aria-expanded="false" aria-controls="collapseExample" >
                    <p >
                        <a class="btn-link text-white font-weight-bold pl-2 " data-toggle="collapse" href="#collapsedrainageirrigation" role="button" aria-expanded="false" aria-controls="collapseExample">
                        {t('teak.drainageirrigation')}
                        </a>
                    </p>
                        <div class="collapse " id="collapsedrainageirrigation">
                            <div class="col-12 mx-auto ">
                                {t('teak.drainageirrigationContent')}
                            </div>
                        </div>
                    </div>
                    <div className="p-1"></div>


                    <div class="border rounded bordercolor_Teak bgcolor_Teak" data-toggle="collapse" href="#collapsepruning" role="button" aria-expanded="false" aria-controls="collapseExample" >
                    <p >
                        <a class="btn-link text-white font-weight-bold pl-2 " data-toggle="collapse" href="#collapsepruning" role="button" aria-expanded="false" aria-controls="collapseExample">
                        {t('teak.pruning')}
                        </a>
                    </p>
                        <div class="collapse " id="collapsepruning">
                            <div class="col-12 mx-auto ">
                                {t('teak.pruningContent')}
                            </div>
                        </div>
                    </div>

                    <div className="p-1"></div>
                    <div class="border rounded bordercolor_Teak bgcolor_Teak"data-toggle="collapse" href="#collapseHarvesting" role="button" aria-expanded="false" aria-controls="collapseExample" >
                    <p >
                        <a class="btn-link text-white font-weight-bold pl-2 " data-toggle="collapse" href="#collapseHarvesting" role="button" aria-expanded="false" aria-controls="collapseExample">
                        {t('teak.Harvesting')}
                        </a>
                    </p>
                        <div class="collapse " id="collapseHarvesting">
                            <div class="col-12 mx-auto ">
                                {t('teak.HarvestingContent')}
                            </div>
                        </div>
                    </div>

                    <div className="p-1"></div>
                    
                   
                    </div>
                </div>
        </div>
        </>
    );
}

export default Teak;