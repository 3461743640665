import React from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next';
import {NavLink} from 'react-router-dom'
import {isMobileOnly} from 'react-device-detect';
import CopyToClipboard from 'react-copy-to-clipboard'


import './styles/Todayleafinfo.css'

const Todayleafinfo = () => {

    const { t, i18n } = useTranslation()
    const whatsappTodaysverse = t('Todaysverse');
    const domainName=" http://trees4people.com";

    function sendWhatsApp() {
        
        if (!isMobileOnly)
        {
            //web.whatpp
            window.open("https://web.whatsapp.com/send?text="+ t( ''+moment().utcOffset("+05:30").format("DD")+'' ) + domainName);
        }
        else
        {
            //whatsapp
            window.open("https://api.whatsapp.com/send?text="+ t( ''+moment().utcOffset("+05:30").format("DD")+'' ) + domainName);
        } 
    }

    return (
        <>
            <div className="container-fluid nav-bg" >
          <div className="row ">
          <div  className="col-12 mx-auto container pt-1 pb-2" >
          <div class="border rounded bordercolor_DailyVerses" >
                <div class="text-white font-weight-bold d-flex justify-content-center bgcolor_DailyVerses " style={{height: '2rem'}}>
                    {t('todayleafinfo')}
                </div>
                <div className="div_whatsapp">
                <NavLink to="#">
                    <CopyToClipboard text={ t( ''+moment().utcOffset("+05:30").format("DD")+'' ) } >
                        <img  src="../images/todayleafinfo/copy_dailyverses.jpg"   className="pr-1" /> 
                    </CopyToClipboard>
                </NavLink>
                <NavLink to="#" >
                    <img  src="../images/todayleafinfo/share_dailyverses.jpg"  class="rounded-circle" onClick={sendWhatsApp} /> 
                </NavLink>
                </div>
                <div class="d-flex justify-content-center p-2 fontcolor_DailyVerses font-weight-bold"> 
                    {t( ''+moment().utcOffset("+05:30").format("DD")+'' )} 
                </div>
            </div>
        </div>
        </div>
            </div>
        </>
    );
};

export default Todayleafinfo;