import react from 'react'
import { useTranslation } from 'react-i18next';


import '../src/styles/Rosewood.css'
const Rosewood = () =>{

    const { t, i18n } = useTranslation()
    return(
        <>
        <div className="container-fluid nav-bg" >
        <div className="p-1"></div>
                <div className="row">
                        <div className="col-12 mx-auto container">
                            <div className="col-12 mx-auto div-rosewood border rounded">
                                    <div class="w-auto h-25 div-rosewood font-weight-bold text-white "> 
                                    {t('menu.rosewood')}
                                    </div>
                            </div>
                        

                        <div className="p-1"></div>
                        <div class="border rounded div-border-rosewood-light" >
                            <div class="text-white font-weight-bold d-flex  div-rosewood-light text-left pl-2 " style={{height: '2rem'}}>
                            {t('rosewood.description')}
                            </div>

                            <div class="row pl-2 ">
                                <div className="col-6 justify-content-center ">{t('rosewood.descriptionContent')}</div>
                                <div className="col-6 justify-content-center ">
                                    <img src='../images/rosewood/T10.jpg'  class="img-fluid rounded-circle" ></img></div>
                            </div>
                            
                        </div>

                        <div className="p-1"></div>


                        <div class="border rounded div-rosewood-light" data-toggle="collapse" href="#collapsesoilSelection" role="button" aria-expanded="false" aria-controls="collapsesoilSelection" >
                            <p >
                                <a class="btn-link text-white font-weight-bold pl-2" data-toggle="collapse" href="#collapsesoilSelection" role="button" aria-expanded="false" aria-controls="collapsesoilSelection">
                                {t('rosewood.soil')}
                                </a>
                            </p>
                            <div class="collapse " id="collapsesoilSelection">
                                <div class="col-12 mx-auto ">
                                    {t('rosewood.soilContent')}
                                </div>
                            </div>
                        </div>
                        <div className="p-1"></div>

                        <div class="border rounded div-rosewood-light" data-toggle="collapse" href="#collapseclimateSelection" role="button" aria-expanded="false" aria-controls="collapseclimateSelection" >
                            <p >
                                <a class="btn-link text-white font-weight-bold pl-2" data-toggle="collapse" href="#collapseclimateSelection" role="button" aria-expanded="false" aria-controls="collapseclimateSelection">
                                {t('rosewood.climate')}
                                </a>
                            </p>
                            <div class="collapse " id="collapseclimateSelection">
                                <div class="col-12 mx-auto ">
                                    {t('rosewood.climateContent')}
                                </div>
                            </div>
                        </div>
                        <div className="p-1"></div>


                        <div class="border rounded div-rosewood-light" data-toggle="collapse" href="#collapsesunlightSelection" role="button" aria-expanded="false" aria-controls="collapsesoilSelection" >
                            <p >
                                <a class="btn-link text-white font-weight-bold pl-2" data-toggle="collapse" href="#collapsesunlightSelection" role="button" aria-expanded="false" aria-controls="collapsesoilSelection">
                                {t('rosewood.sunlight')}
                                </a>
                            </p>
                            <div class="collapse " id="collapsesunlightSelection">
                                <div class="col-12 mx-auto ">
                                    {t('rosewood.sunlightContent')}
                                </div>
                            </div>
                        </div>
                        <div className="p-1"></div>

                        <div class="border rounded div-rosewood-light" data-toggle="collapse" href="#collapsePPSelection" role="button" aria-expanded="false" aria-controls="collapsePPSelection" >
                            <p >
                                <a class="btn-link text-white font-weight-bold pl-2" data-toggle="collapse" href="#collapsePPSelection" role="button" aria-expanded="false" aria-controls="collapsePPSelection">
                                {t('rosewood.preplantationoperations')}
                                </a>
                            </p>
                            <div class="collapse " id="collapsePPSelection">
                                <div class="col-12 mx-auto ">
                                    {t('rosewood.preplantationoperationsContent')}
                                </div>
                            </div>
                        </div>
                        <div className="p-1"></div>
                        <div class="border rounded div-rosewood-light" data-toggle="collapse" href="#collapseirrigationSelection" role="button" aria-expanded="false" aria-controls="collapseirrigationSelection" >
                            <p >
                                <a class="btn-link text-white font-weight-bold pl-2" data-toggle="collapse" href="#collapseirrigationSelection" role="button" aria-expanded="false" aria-controls="collapseirrigationSelection">
                                {t('rosewood.drainageirrigation')}
                                </a>
                            </p>
                            <div class="collapse " id="collapseirrigationSelection">
                                <div class="col-12 mx-auto ">
                                    {t('rosewood.drainageirrigationContent')}
                                </div>
                            </div>
                        </div>
                        <div className="p-1"></div>

                        <div class="border rounded div-rosewood-light" data-toggle="collapse" href="#collapseHarvestingSelection" role="button" aria-expanded="false" aria-controls="collapseHarvestingSelection" >
                            <p >
                                <a class="btn-link text-white font-weight-bold pl-2" data-toggle="collapse" href="#collapseHarvestingSelection" role="button" aria-expanded="false" aria-controls="collapseHarvestingSelection">
                                {t('rosewood.Harvesting')}
                                </a>
                            </p>
                            <div class="collapse " id="collapseHarvestingSelection">
                                <div class="col-12 mx-auto ">
                                    {t('rosewood.HarvestingContent')}
                                </div>
                            </div>
                        </div>
                        <div className="p-1"></div>


                        </div>
                        
                    </div>
                </div>
        </>
    );
}

export default Rosewood;