import React from 'react'
import { useTranslation } from 'react-i18next';
import {NavLink } from 'react-router-dom';


import './styles/Home.css'

const Home = () => {

    const { t, i18n } = useTranslation()
    return(
        <>
        <div className="container-fluid nav-bg" >
                 <div className="container mt-4" >
                         <div className="row justify-content-center "  >
                             {/* <div>{ { currDate }  }  </div> */}

                             <div style={{padding:"5px"}}>
                                 <div className="border bcolor_teak rounded">
                                     <div style={{padding:"10px"}}>
                                        <NavLink to="/Teak" >
                                            <img  src="../images/home/Teak_Home_550_352.jpg" alt="Card image" class="img-fluid" width="500px;" /> 
                                        </NavLink>
                                     </div>
                                 </div>
                             </div>
                             <div style={{padding:"5px"}}>
                                 <div className="border bcolor_redsandal rounded">
                                     <div style={{padding:"10px"}}>
                                        <NavLink to="/Redsandal" >
                                            <img  src="../images/home/RedSandal_550_352.jpg" alt="Card image" class="img-fluid" width="500px;" /> 
                                        </NavLink>                                  
                                     </div>
                                 </div>
                             </div>
                             <div style={{padding:"5px"}}>
                                 <div className="border bcolor_sandalwood rounded">
                                     <div style={{padding:"10px"}}>
                                        <NavLink to="/Sandalwood" >
                                            <img  src="../images/home/SandalWood_550_352.jpg" alt="Card image" class="img-fluid" width="500px;"  /> 
                                        </NavLink>  
                                     </div>
                                 </div>
                             </div>
                             <div style={{padding:"5px"}}>
                                 <div className="border bcolor_rosewood rounded">
                                     <div style={{padding:"10px"}}>
                                        <NavLink to="/Rosewood" >
                                            <img  src="../images/home/Rosewood_550_352.jpg" alt="Card image" class="img-fluid" width="500px;"  /> 
                                        </NavLink>
                                     </div>
                                 </div>
                             </div>
                             
                         </div>
                  </div> 
            </div>
        </>
        )

}
export default Home;