import react from 'react'
import { useTranslation } from 'react-i18next';

import '../src/styles/Sandal.css'

const Sandalwood = () =>{

    const { t, i18n } = useTranslation()
    return(
        <>
        <div className="container-fluid nav-bg" >
        <div className="p-1"></div>
                <div className="row">
                    <div className="col-12 mx-auto container">
                        <div className="col-12 mx-auto gallery-div-horizondal-line border rounded">
                                <div class="w-auto h-25 gallery-div-horizondal-line font-weight-bold text-white "> 
                                {t('menu.sandalwood')}
                              </div>
                        </div>

                        <div className="p-1"></div>
                        <div class="border rounded sandal-border-horizondal-line" >
                            <div class="text-white font-weight-bold d-flex  sandal-div-horizondal-line-light text-left pl-2 " style={{height: '2rem'}}>
                            {t('sandal.description')}
                            </div>

                            <div class="row pl-2 ">
                                <div className="col-6 justify-content-center ">{t('sandal.descriptionContent')}</div>
                                <div className="col-6 justify-content-center ">
                                    <img src='../images/sandal/T10.jpg'  class="img-fluid rounded-circle" ></img></div>
                            </div>
                            
                        </div>
                        <div className="p-1"></div>

                        <div class="border rounded sandal-div-horizondal-line-light" data-toggle="collapse" href="#collapseLandSelection" role="button" aria-expanded="false" aria-controls="collapseLandSelection" >
                            <p >
                                <a class="btn-link text-white font-weight-bold pl-2" data-toggle="collapse" href="#collapseLandSelection" role="button" aria-expanded="false" aria-controls="collapseLandSelection">
                                {t('sandal.landselection')}
                                </a>
                            </p>
                            <div class="collapse " id="collapseLandSelection">
                                <div class="col-12 mx-auto ">
                                    {t('sandal.landselectionContent')}
                                </div>
                            </div>
                        </div>
                        <div className="p-1"></div>
                        <div class="border rounded sandal-div-horizondal-line-light" data-toggle="collapse" href="#collapseSoilSelection" role="button" aria-expanded="false" aria-controls="collapseSoilSelection" >
                            <p >
                                <a class="btn-link text-white font-weight-bold pl-2" data-toggle="collapse" href="#collapseSoilSelection" role="button" aria-expanded="false" aria-controls="collapseSoilSelection">
                                {t('sandal.soil')}
                                </a>
                            </p>
                            <div class="collapse " id="collapseSoilSelection">
                                <div class="col-12 mx-auto ">
                                    {t('sandal.soilContent')}
                                </div>
                            </div>
                        </div>
                        <div className="p-1"></div>

                        <div class="border rounded sandal-div-horizondal-line-light" data-toggle="collapse" href="#collapseClimateSelection" role="button" aria-expanded="false" aria-controls="collapseClimateSelection" >
                            <p >
                                <a class="btn-link text-white font-weight-bold pl-2" data-toggle="collapse" href="#collapseClimateSelection" role="button" aria-expanded="false" aria-controls="collapseClimateSelection">
                                {t('sandal.climate')}
                                </a>
                            </p>
                            <div class="collapse " id="collapseClimateSelection">
                                <div class="col-12 mx-auto ">
                                    {t('sandal.climateContent')}
                                </div>
                            </div>
                        </div>
                        <div className="p-1"></div>

                        <div class="border rounded sandal-div-horizondal-line-light" data-toggle="collapse" href="#collapseSunlightSelection" role="button" aria-expanded="false" aria-controls="collapseSunlightSelection" >
                            <p >
                                <a class="btn-link text-white font-weight-bold pl-2" data-toggle="collapse" href="#collapseSunlightSelection" role="button" aria-expanded="false" aria-controls="collapseSunlightSelection">
                                {t('sandal.sunlight')}
                                </a>
                            </p>
                            <div class="collapse " id="collapseSunlightSelection">
                                <div class="col-12 mx-auto ">
                                    {t('sandal.sunlightContent')}
                                </div>
                            </div>
                        </div>
                        <div className="p-1"></div>

                        <div class="border rounded sandal-div-horizondal-line-light" data-toggle="collapse" href="#collapsePPSelection" role="button" aria-expanded="false" aria-controls="collapsePPSelection" >
                            <p >
                                <a class="btn-link text-white font-weight-bold pl-2" data-toggle="collapse" href="#collapsePPSelection" role="button" aria-expanded="false" aria-controls="collapsePPSelection">
                                {t('sandal.preplantationoperations')}
                                </a>
                            </p>
                            <div class="collapse " id="collapsePPSelection">
                                <div class="col-12 mx-auto ">
                                    {t('sandal.preplantationoperationsContent')}
                                </div>
                            </div>
                        </div>
                        <div className="p-1"></div>

                        <div class="border rounded sandal-div-horizondal-line-light" data-toggle="collapse" href="#collapseDrainageirrigationSelection" role="button" aria-expanded="false" aria-controls="collapseDrainageirrigationSelection" >
                            <p >
                                <a class="btn-link text-white font-weight-bold pl-2" data-toggle="collapse" href="#collapseDrainageirrigationSelection" role="button" aria-expanded="false" aria-controls="collapseDrainageirrigationSelection">
                                {t('sandal.drainageirrigation')}
                                </a>
                            </p>
                            <div class="collapse " id="collapseDrainageirrigationSelection">
                                <div class="col-12 mx-auto ">
                                    {t('sandal.drainageirrigationContent')}
                                </div>
                            </div>
                        </div>
                        <div className="p-1"></div>

                        <div class="border rounded sandal-div-horizondal-line-light" data-toggle="collapse" href="#collapseHarvestingSelection" role="button" aria-expanded="false" aria-controls="collapseHarvestingSelection" >
                            <p >
                                <a class="btn-link text-white font-weight-bold pl-2" data-toggle="collapse" href="#collapseHarvestingSelection" role="button" aria-expanded="false" aria-controls="collapseHarvestingSelection">
                                {t('sandal.Harvesting')}
                                </a>
                            </p>
                            <div class="collapse " id="collapseHarvestingSelection">
                                <div class="col-12 mx-auto ">
                                    {t('sandal.HarvestingContent')}
                                </div>
                            </div>
                        </div>
                        <div className="p-1"></div>





                            {/* <div className="col-12 mx-auto ">
                                <div class="p-1 w-auto h-25 gallery-div-horizondal-line1 font-weight-bold"> 
                                {t('menu.sandalwood')}
                                </div>
                            </div>
                            <div className="col-12 mx-auto bg-white">
                                <div class="p-3 w-auto h-50 bg-white"> 
                                {t('Test')}
                                </div>
                            </div>

                            <div className="col-12 mx-auto bg-light">
                                <div class="p-1 w-auto h-25 bg-light font-italic"> 
                                {t('Description')}
                                </div>
                            </div>
                            <div className="col-12 mx-auto bg-white">
                                <div class="p-3 w-auto h-50 bg-white"> 
                                {t('Test')}
                                </div>
                            </div> */}
                    </div>
                </div>
        </div>
        </>
    );
}

export default Sandalwood;