import React from 'react';
import Gallery from "react-photo-gallery-react17";
import { useTranslation } from 'react-i18next'
import ImgsViewer from "react-images-viewer";

import {NavLink } from 'react-router-dom';

const GalleryRedsandal = () =>{

    const { t, i18n } = useTranslation()



  const photos = [
    {}
  ];


  const photosTeak = [
    {
      src: "../images/galleryredsandal/R1.jpg",
      width: 1,
      height: 1
    },
    {
      src: "../images/galleryredsandal/R2.jpg",
      width: 1,
      height:1
    },
    {
      src: "../images/galleryredsandal/R3.jpg",
      width: 1,
      height: 1
     },
     {
        src: "../images/galleryredsandal/R4.jpg",
        width: 1,
        height: 1
       },
       {
        src: "../images/galleryredsandal/R5.jpg",
        width: 1,
        height: 1
       },
       {
        src: "../images/galleryredsandal/R6.jpg",
        width: 1,
        height: 1
       },
       {
        src: "../images/galleryredsandal/R7.jpg",
        width: 1,
        height: 1
       },
       {
        src: "../images/galleryredsandal/R8.jpg",
        width: 1,
        height: 1
       },
       {
        src: "../images/galleryredsandal/R9.jpg",
        width: 2,
        height: 1
       },
       {
        src: "../images/galleryredsandal/R10.jpg",
        width: 2,
        height: 1
       }


    ]

    return(
        <>

           <div className="container-fluid nav-bg" >
                <div className="row">
                    <div className="col-12 mx-auto">
                        <div className="col-12 mx-auto bg-light">
                                <div class="p-1 w-auto h-25 bg-light "> 
                                <strong>
                                <NavLink to="/Gallery" >
                                        {t('menu.gallery')}
                                </NavLink>
                                  -  {t('gallery.redsandalwood')}
                                </strong>
                                </div>
                                <div className="p-1"></div>
                                <div>
                                    <Gallery photos={photosTeak}/>
                                </div>


                        </div>
                    </div>
                </div>
            </div> 

        </>
    );
}

export default GalleryRedsandal