import React from 'react';
import {
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, Button
  } from 'reactstrap';

import "./styles/Gallery.css"
import { useTranslation } from 'react-i18next'

import {NavLink} from 'react-router-dom';

const Gallery = () =>
{

    const { t, i18n } = useTranslation()
    return(
        <>
            <div className="container-fluid nav-bg" >
            <div className="p-1"></div>
                <div className="row">
                <div className="col-12 mx-auto container">
                        <div className="col-12 mx-auto bg-light">
                            <div class="p-1 w-auto h-25 bg-light font-weight-bold"> 
                            {t('menu.gallery')}
                            </div>
                        </div>
                </div>
                <div className="container mt-4" >
                
                    <div className="row justify-content-center "  >
                   
                        
                            <div  >
                            <Card className="border border-success" style={{height: '15rem'}}>
                                <CardTitle className="p-3 mb-2 bg-success text-white font-weight-bold">{t('gallery.teak')}</CardTitle>
                                <NavLink to="/GalleryTeak" >
                                    <CardImg top width="100%" src="../images/gallery/Gallery_Teak_300_151.jpg" alt="Card image cap" />
                                </NavLink>
                            </Card>
                            </div>

                            <div>
                            <Card  className="border border-danger " style={{height: '15rem'}} >
                                <CardTitle className="p-3 mb-2 bg-danger text-white font-weight-bold">{t('gallery.redsandalwood')}</CardTitle>
                                <NavLink to="/GalleryRedsandal" >
                                    <CardImg top width="100%" src="../images/gallery/Gallery_Redsandal_300_151.jpg" alt="Card image cap" />
                                </NavLink>
                            </Card>
                            </div>
                            <div>
                            <Card className="border border-warning" style={{height: '15rem'}} >
                                <CardTitle className="p-3 mb-2 bg-warning text-white font-weight-bold"><CardText>{t('gallery.sandalwood')}</CardText></CardTitle>
                               
                                <NavLink to="/GallerySandal" >
                                    <CardImg top width="100%" src="../images/gallery/Gallery_Sandal_300_151.jpg" alt="Card image cap" />
                                </NavLink>
                            </Card>
                            </div>
                            <div>
                            <Card className="border border-info" style={{height: '15rem'}}>
                                <CardTitle className="p-3 mb-2 bg-info text-white font-weight-bold">{t('gallery.rosewood')}</CardTitle>
                                
                                <NavLink to="/GalleryRosewood" >
                                    <CardImg top width="100%" src="../images/gallery/Gallery_Rosewood_300_151.jpg" alt="Card image cap" />
                                </NavLink>
                            </Card>
                            </div>
                
                        </div>
                    </div>
                </div>
         </div>


        </>
    );
}

export default Gallery