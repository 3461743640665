import React from 'react';
import Gallery from "react-photo-gallery-react17";
import { useTranslation } from 'react-i18next'
import ImgsViewer from "react-images-viewer";

import {NavLink } from 'react-router-dom';


const GalleryTeak = () =>{

    const { t, i18n } = useTranslation()



  const photos = [
    {}
  ];


  const photosTeak = [
    {
      src: "../images/galleryteak/T1.jpg",
      width: 1,
      height: 1
    },
    {
      src: "../images/galleryteak/T2.jpg",
      width: 1,
      height: 1
    },
    {
      src: "../images/galleryteak/T3.jpg",
      width: 1,
      height: 1
     },
     {
        src: "../images/galleryteak/T4.jpg",
        width: 1,
        height: 1
       },
       {
        src: "../images/galleryteak/T5.jpg",
        width: 1,
        height: 1
       },
       {
        src: "../images/galleryteak/T6.jpg",
        width: 1,
        height: 1
       },
       {
        src: "../images/galleryteak/T7.jpg",
        width: 1,
        height: 1
       },
       {
        src: "../images/galleryteak/T8.jpg",
        width: 1,
        height: 1
       },
       {
        src: "../images/galleryteak/T9.jpg",
        width: 2,
        height: 1
       },
       {
        src: "../images/galleryteak/T10.jpg",
        width: 2,
        height: 1
       }


    ]

    return(
        <>

           <div className="container-fluid nav-bg" >
                <div className="row">
                    <div className="col-12 mx-auto">
                        <div className="col-12 mx-auto bg-light">
                                <div class="p-1 w-auto h-25 bg-light "> 
                                  <div className="row">
                                  <strong>
                                      <NavLink to="/Gallery" >
                                        {t('menu.gallery')}
                                      </NavLink>
                                      - {t('gallery.teak')}
                                  </strong>
                                      
                                  </div>
                                </div>
                                <div className="p-1"></div>
                                <div>
                                    <Gallery photos={photosTeak}/>
                                </div>


                        </div>
                    </div>
                </div>
            </div> 

        </>
    );
}

export default GalleryTeak