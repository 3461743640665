import react from 'react'
import { useTranslation } from 'react-i18next';

import '../src/styles/Redsandal.css'

//import { OverlayTrigger, Popover, Button } from 'react-bootstrap';
//import 'bootstrap/dist/css/bootstrap.min.css';

const Redsandal = () =>{

    const { t, i18n } = useTranslation()


    function handleCollapse (){
        //var nav = document.getElementById("navbarSupportedContent");
        var btn = document.getElementById("btnpopup");
        //nav.classList.remove("show");
        btn.classList.add("collapsed");
      };
   

    return(
        <>
        <div className="container-fluid nav-bg"  >
        <div className="p-1"></div>
                <div className="row">
                    <div className="col-12 mx-auto container">
                        <div className="col-12 mx-auto div-redsandal-line border rounded">
                            <div class="w-auto h-25 div-redsandal-line font-weight-bold text-white "> 
                            {t('menu.redsandalwood')}
                            </div>
                        </div>

                        <div className="p-1"></div>
                        <div class="border rounded div-bordercolor_redsandal " >
                            <div class="text-white font-weight-bold d-flex  div-redsandal-line-light text-left pl-2 " style={{height: '2rem'}}>
                            {t('redsandal.description')}
                            </div>

                            <div class="row pl-2 ">
                                <div className="col-6 justify-content-center ">{t('redsandal.descriptionContent')}</div>
                                <div className="col-6 justify-content-center ">
                                    <img src='../images/redsandal/T1.jpg'  class="img-fluid rounded-circle" ></img></div>
                            </div>
                            
                        </div>
                        <div className="p-1"></div>

                        {/* <div class="border rounded div-redsandal-line-light" data-toggle="collapse" href="#collapsedescription" role="button" aria-expanded="false" aria-controls="collapsedescription" >
                        <p >
                            <a class="btn-link text-white font-weight-bold pl-2 " data-toggle="collapse" href="#collapsedescription" role="button" aria-expanded="false" aria-controls="collapsedescription">
                            {t('redsandal.description')}
                            </a>
                        </p>
                        <div class="collapse " id="collapsedescription">
                            <div class="col-12 mx-auto ">
                                {t('redsandal.descriptionContent')}
                            </div>
                        </div>
                    </div>
                    <div className="p-1"></div> */}

                    
                    <div class="border rounded div-redsandal-line-light" data-toggle="collapse" href="#collapseLandSelection" role="button" aria-expanded="false" aria-controls="collapseLandSelection" >
                    <p >
                        <a class="btn-link text-white font-weight-bold pl-2" data-toggle="collapse" href="#collapseLandSelection" role="button" aria-expanded="false" aria-controls="collapseLandSelection">
                        {t('redsandal.landselection')}
                        </a>
                    </p>
                        <div class="collapse " id="collapseLandSelection">
                            <div class="col-12 mx-auto ">
                                {t('redsandal.landselectionContent')}
                            </div>
                        </div>
                    </div>
                    <div className="p-1"></div>


                    <div class="border rounded div-redsandal-line-light" data-toggle="collapse" href="#collapseSoil" role="button" aria-expanded="false" aria-controls="collapseSoil" >
                    <p >
                        <a class="btn-link text-white font-weight-bold pl-2 " data-toggle="collapse" href="#collapseSoil" role="button" aria-expanded="false" aria-controls="collapseSoil">
                        {t('redsandal.soil')}
                        </a>
                    </p>
                        <div class="collapse " id="collapseSoil">
                            <div class="col-12 mx-auto ">
                                {t('redsandal.soilContent')}
                            </div>
                        </div>
                    </div>

                    <div className="p-1"></div>


                    <div class="border rounded div-redsandal-line-light" data-toggle="collapse" href="#collapseClimate" role="button" aria-expanded="false" aria-controls="collapseClimate" >
                    <p >
                        <a class="btn-link text-white font-weight-bold pl-2 " data-toggle="collapse" href="#collapseClimate" role="button" aria-expanded="false" aria-controls="collapseClimate">
                        {t('redsandal.climate')}
                        </a>
                    </p>
                        <div class="collapse " id="collapseClimate">
                            <div class="col-12 mx-auto ">
                                {t('redsandal.climateContent')}
                            </div>
                        </div>
                    </div>
                    <div className="p-1"></div>

                    <div class="border rounded div-redsandal-line-light" data-toggle="collapse" href="#collapseSunlight" role="button" aria-expanded="false" aria-controls="collapseSunlight" >
                    <p >
                        <a class="btn-link text-white font-weight-bold pl-2 " data-toggle="collapse" href="#collapseSunlight" role="button" aria-expanded="false" aria-controls="collapseSunlight">
                        {t('redsandal.sunlight')}
                        </a>
                    </p>
                        <div class="collapse " id="collapseSunlight">
                            <div class="col-12 mx-auto ">
                                {t('redsandal.sunlightContent')}
                            </div>
                        </div>
                    </div>
                    <div className="p-1"></div>

                    <div class="border rounded div-redsandal-line-light"data-toggle="collapse" href="#collapsepreplantationoperations" role="button" aria-expanded="false" aria-controls="collapsepreplantationoperations" >
                    <p >
                        <a class="btn-link text-white font-weight-bold pl-2 " data-toggle="collapse" href="#collapsepreplantationoperations" role="button" aria-expanded="false" aria-controls="collapsepreplantationoperations">
                        {t('redsandal.preplantationoperations')}
                        </a>
                    </p>
                        <div class="collapse " id="collapsepreplantationoperations">
                            <div class="col-12 mx-auto ">
                                {t('redsandal.preplantationoperationsContent')}
                            </div>
                        </div>
                    </div>
                    
                    <div className="p-1"></div>

                    <div class="border rounded div-redsandal-line-light" data-toggle="collapse" href="#collapsedrainageirrigation" role="button" aria-expanded="false" aria-controls="collapsedrainageirrigation" >
                    <p >
                        <a class="btn-link text-white font-weight-bold pl-2 " data-toggle="collapse" href="#collapsedrainageirrigation" role="button" aria-expanded="false" aria-controls="collapsedrainageirrigation">
                        {t('redsandal.drainageirrigation')}
                        </a>
                    </p>
                        <div class="collapse " id="collapsedrainageirrigation">
                            <div class="col-12 mx-auto ">
                                {t('redsandal.drainageirrigationContent')}
                            </div>
                        </div>
                    </div>
                    <div className="p-1"></div>

                    <div class="border rounded div-redsandal-line-light"data-toggle="collapse" href="#collapseHarvesting" role="button" aria-expanded="false" aria-controls="collapseExample" >
                    <p >
                        <a class="btn-link text-white font-weight-bold pl-2 " data-toggle="collapse" href="#collapseHarvesting" role="button" aria-expanded="false" aria-controls="collapseExample">
                        {t('redsandal.Harvesting')}
                        </a>
                    </p>
                        <div class="collapse " id="collapseHarvesting">
                            <div class="col-12 mx-auto ">
                                {t('redsandal.HarvestingContent')}
                            </div>
                        </div>
                    </div>

                    <div className="p-1"></div>
                        
                    </div>
                </div>
        </div>
        </>
    );
}

export default Redsandal;