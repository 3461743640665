
import React , { Component, Suspense } from 'react';


import {Routes,Route} from 'react-router-dom';
import Home from './Home';


import './App.css';
import './i18n';

import Navbarcustom from './NavbarCustom';
import CarouselComponent from './carouselcomponent';
import Todayleafinfo from './todayleafinfo';
import Teak from './teak';
import Redsandal from './redsandal';
import Sandalwood from './sandalwood';
import Rosewood from './rosewood';

import Gallery from './gallery';
import GalleryTeak from './galleryTeak';
import GalleryRedsandal from './galleryRedsandal';
import GallerySandal from './gallerySandal';
import GalleryRosewood from './galleryRosewood';
import Footer from './footer';
import Teaknew from './Teaknew';




function App(props) {
  return (
    <>
    <Suspense fallback={null}>
      <Navbarcustom />
      <CarouselComponent/>
      <Todayleafinfo/>
      <Routes >
                  <Route  path="/" element={<Home />}/>
                  <Route  path="/Teak" element={<Teak/>}/>
                  <Route  path="/Redsandal" element={<Redsandal/>}/>
                  <Route  path="/Sandalwood" element={<Sandalwood/>}/>
                  <Route  path="/Rosewood" element={<Rosewood/>}/>
                  <Route  path="/Gallery" element={<Gallery/>}/>
                  <Route  path="/GalleryTeak" element={<GalleryTeak/>}/>
                  <Route  path="/GalleryRedsandal" element={<GalleryRedsandal/>}/>
                  <Route  path="/GallerySandal" element={<GallerySandal/>}/>
                  <Route  path="/GalleryRosewood" element={<GalleryRosewood/>}/>
                  <Route  path="/Teaknew" element={<Teaknew/>}/>
      </Routes>
      <Footer/>
    </Suspense>
     
    </>
    
  );
}

export default App;
