import React from 'react';
//import ListGroup from 'react-bootstrap/ListGroup'
import './styles/Footer.css'
import { useTranslation } from 'react-i18next'
import {NavLink } from 'react-router-dom';

const Footer =()=>{

    const { t, i18n } = useTranslation()

    const changeLanguage = (event) => {
      i18n.changeLanguage(event.target.value)
    }

    return(
    <> 
    <div className="container-fluid nav-bg" >
        <div className="col-12 mx-auto footer-div-horizondal-line">
        <div class="p-3 w-auto h-25 footer-div-horizondal-line"> </div>
        </div>
     </div>
      <div className="container-fluid nav-bg " >
            <div className="col-12 mx-auto footer-div-bgcolor">
                    <div className="col-12 mx-auto ">
                        
                        <div class="p-3   mb-2 container footer-font">
                        
                            <div className="row  h-100 justify-content-center" >
                                    <div class="p-3  footer-div-list justify-content-center" >
                                        <div>
                                            <img src="../images/footer/Bastin_100_100.jpg"  class="rounded-circle" ></img>
                                        </div>
                                    </div>
                                    
                                    <div class="p-3 footer-div-list" >
                                        <h6 className="footer-div-content-item">{t('footer.name')} </h6> 
                                        <div className="row"> 
                                            <div className='ml-3'>
                                                <img src="../images/footer/mobile.jpg"  class="rounded-circle" ></img>
                                            </div>
                                            <div className='m-1' ></div>
                                            <div>
                                                <img src="../images/footer/whatsapp.jpg"  class="rounded-circle" ></img>
                                            </div>
                                            <div  className="pt-1">
                                                <h6 className="footer-div-content-item">{t('footer.mobileno')}</h6>
                                            </div>
                                        </div>

                                        <div className="row mt-1" >
                                            <div className="ml-3 mr-2">
                                                <img src="../images/footer/email.jpg"  class="rounded-circle" ></img>
                                            </div>

                                            <div className="pt-1">
                                                <h6 className="footer-div-content-item">{t('footer.emailId')}</h6>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="p-3 footer-div-list" >
                                        
                                        <a className="footer-div-content-item" href='mailto:info@trees4people.in'>info@trees4people.in</a>  
                                        <h6 className="footer-div-content-item">{t('footer.active')} </h6> 
                                        <h6 className="footer-div-content-item">{t('footer.live')} </h6>
                                        <h6 className="footer-div-content-item">{t('footer.version')} </h6> 
                                    </div>
                                   
                                    <div class="p-3 footer-div-list">
                                        <img src="../images/footer/compatibleicons.jpg"  class="rounded-circle" ></img>

                                    </div>
                                    
                                    
                                   
                            </div>
                        </div>
                    </div>
                    </div>
               
    </div>
    </>
    );
};

export default Footer;