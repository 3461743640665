import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import './styles/CarouselOverride.css';


export default function CarouselComponent() {
    return (
        <div className="container-fluid nav-bg" >
            <div className="col-14 mx-auto">
        <div className="carousel-wrapper">
            <Carousel infiniteLoop useKeyboardArrows autoPlay showThumbs={false} >
                <div>
                    <img src="../images/carousel/Teak_1686_552.jpg" />
                </div>
                <div>
                    <img src="../images/carousel/RedSandal_1686_552_1.jpg" />
                </div>
                <div>
                    <img src="../images/carousel/Sandalwood_1686_552.jpg" />
                </div>
                <div>
                    <img src="../images/carousel/RoseWood_1686_552.jpg" />
                </div>
            </Carousel>
        </div>
        </div>
        </div>
    );
}