
import React, { Component } from 'react'
import { useTranslation } from 'react-i18next';


export default class Teaknew extends Component {
    constructor(props){
        super(props)
        this.state = {
          name: 'constructor Method'
        }
        //const { t, i18n } = useTranslation();

      }

      componentDidMount(){
        setTimeout(() => {
          this.setState({name: "componentDidMount Method"})
        }, 5000)
      }

      componentDidUpdate() {
        document.getElementById('current-state').innerHTML = "The current state is " + this.state.name

        var btn = document.getElementById("btnDesc");
        //nav.classList.remove("show");
        btn.classList.add("collapsed");

      }

      render() {
      
      
        return (
            <>
               <h5>This is a {this.state.name}</h5>
                <p id="current-state"></p>
                <p id="previous-state"></p>

                <div className="p-1"></div>                 
                    <div id="accordion">
                        <div class=" col-12 mx-auto bg-success">
                            <div class="col-12 mx-auto " id="headingOne">
                            <h5 class="mb-0">
                                <button id="btnDesc" class="btn btn-link font-weight-bold text-white" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                Description

                                </button>
                            </h5>
                            </div>

                            <div id="collapseOne" class="collapse show bg-success" aria-labelledby="headingOne" data-parent="#accordion">
                            <div class="card-body">
                                <div className="row justify-content-center mx-auto"> 
                                    <div >
                                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.
                                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 
                                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.  
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>

            </>
        )
    }
}

