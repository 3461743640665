import react from "react";
import {NavLink} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './styles/Navbarcustom.css'

const Navbarcustom = () => {

    const { t, i18n } = useTranslation()

    const changeLanguage = (event) => {
      i18n.changeLanguage(event.target.value)
    }

    function handleCollapse (){
        var nav = document.getElementById("navbarSupportedContent");
        var btn = document.getElementById("btntoggler");
        nav.classList.remove("show");
        btn.classList.add("collapsed");
      };

    const divStyle = {
        display: 'flex',
        alignItems: 'center'
      };

    return(
        <>
        <div className="container-fluid nav-bg navbar-font" >
            <div className="col-12 mx-auto">
                            <div className="row navbar-div-language-selection" onChange={changeLanguage}>
                                <div style={divStyle} >
                                    <input type="radio" value="en" name="language" defaultChecked />&nbsp; English    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <input type="radio" value="ta-IN" name="language"/>&nbsp; தமிழ்
                                </div>
                            </div>
            </div>
                            <div className="row">
                                <div className="col-12 mx-auto">
                                        <div className="row">
                                            <div className="col-12 mx-auto">
                                            <nav className="navbar navbar-expand-lg navbar-light navbar-div-content">
                                                    <a className="navbar-brand font-weight-bold" href="/">{t('menu.title')}</a>
                                    
                                                    <button id="btntoggler" className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                                        <span className="navbar-toggler-icon"></span>
                                                    </button>
                                                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                                        <ul className="navbar-nav ml-auto">
                                                            <li  >
                                                                <NavLink className={({ isActive }) => isActive ? "nav-link-bold menuMargin nav-link" : "nav-link menuMargin" }   exact aria-current="page" to="/" onClick={handleCollapse}  >{t('menu.home')}  </NavLink>
                                                            </li>
                                                            <li>
                                                                <NavLink  className={({ isActive }) => isActive ? "nav-link-bold menuMargin nav-link" : "nav-link menuMargin" } to="/Teak" onClick={handleCollapse} >{t('menu.teak')}</NavLink>
                                                            </li>
                                                            <li>
                                                                <NavLink  className={({ isActive }) => isActive ? "nav-link-bold menuMargin nav-link" : "nav-link menuMargin" }  to="/Redsandal" onClick={handleCollapse} >{t('menu.redsandalwood')}</NavLink>
                                                            </li>
                                                            <li>
                                                                <NavLink  className={({ isActive }) => isActive ? "nav-link-bold menuMargin nav-link" : "nav-link menuMargin" }  to="/Sandalwood" onClick={handleCollapse} >{t('menu.sandalwood')}</NavLink>
                                                            </li>
                                                            <li>
                                                                <NavLink  className={({ isActive }) => isActive ? "nav-link-bold menuMargin nav-link" : "nav-link menuMargin" }  to="/Rosewood" onClick={handleCollapse} >{t('menu.rosewood')}</NavLink>
                                                            </li>
                                                            <li>
                                                                <NavLink  className={({ isActive }) => isActive ? "nav-link-bold menuMargin nav-link" : "nav-link menuMargin" }  to="/Gallery" onClick={handleCollapse} >{t('menu.gallery')}</NavLink>
                                                            </li>
                                                            {
                                                            }
                                                        </ul>
                                                    </div>

                                            </nav>
                                               
                                            </div>
                                    </div>
                                </div>
                            </div>
                            
            
        </div>
        </>
    );
}
export default Navbarcustom